/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import { makeHandle as e } from "../../core/handleUtils.js";
import { watch as t, syncAndInitial as r } from "../../core/reactiveUtils.js";
const n = new WeakMap();
function a(o) {
  return !o.destroyed && (n.has(o) || o.addHandles([t(() => {
    const e = o.parent;
    return !(!e || !("type" in e)) && ("catalog-dynamic-group" === e.type || a(e));
  }, e => n.set(o, e), r), e(() => n.delete(o))]), n.get(o));
}
function o(e) {
  return e.parent && "type" in e.parent && "catalog-dynamic-group" === e.parent.type ? e.parent.parent : null;
}
function i(e, t) {
  const r = e.parent,
    n = e.layer;
  if ("map-image" !== n?.type || !n.sourceJSON || !r) return !1;
  const a = n.sourceJSON.layers;
  if (!a) return !1;
  const o = a.find(t => e.id === t.id),
    i = "footprints" === t ? "Feature Layer" : "Catalog Dynamic Group Layer";
  return o?.type === i && "Catalog Layer" === a.find(e => e.id === r.id)?.type;
}
export { o as getCatalogLayerForLayer, i as isCatalogSublayer, a as isLayerFromCatalog };